// import { getOrders, getOrder } from '@/ship2u/utils/tools'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // fetchOrders(ctx, queryParams) {
    //   return getOrders({ params: queryParams })
    // },
    // fetchOrder(ctx, { id }) {
    //   return getOrder({ id })
    // },
  },
}
